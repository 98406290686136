/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/*eslint-disable*/
import React from "react";
import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { BsTwitter, BsDiscord } from "react-icons/bs";

export default function Footer(props) {
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent="space-between"
      px="30px"
      pb="20px"
    >
      <Text
        fontSize="sm"
        color="white"
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        &copy; {1900 + new Date().getYear()}{" "}
        <Link href="https://x.com/spincoinada?s=21" target="_blank">
          <Icon as={BsTwitter} boxSize={3} mr="10px" ml="10px" />
        </Link>
        <Link href="https://discord.gg/6TP4cpvwnr" target="_blank">
          <Icon as={BsDiscord} boxSize={3} mr="10px" ml="10px" />
        </Link>
        &{" "}
        <Link
          onClick={() => {
            document.execCommand(
              "copy",
              true,
              "687f090a8e4ffa82ac311ad8c09e0804d9fa40c5532b7562435a6300"
            );
            alert("copied");
          }}
          target="_blank"
        >
          Policy ID: 687f090a8e4f.....
        </Link>
      </Text>
      <List display="flex">
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            color="white"
            fontSize="sm"
            href="https://x.com/spincoinada?s=21"
          >
            <Icon as={BsTwitter} boxSize={3} mr="10px" ml="10px" />
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            color="white"
            fontSize="sm"
            href="https://discord.gg/6TP4cpvwnr"
          >
            <Icon as={BsDiscord} boxSize={3} mr="10px" ml="10px" />
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
