// Chakra imports
import {
  Box,
  Button,
  CircularProgress,
  Center,
  CircularProgressLabel,
  Flex,
  Grid,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Image,
} from "@chakra-ui/react";
// Styles for the circular progressbar
import medusa from "assets/img/cardimgfree.png";
import baccarat from "assets/img/baccarat.png";
import slots from "assets/img/slots.png";
import dice from "assets/img/dice.png";
import poker from "assets/img/poker.png";
import plinko from "assets/img/plinko.png";
import roulette from "assets/img/roulette.png";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
// Icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  RocketIcon,
  StatsIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import DashboardTableRow from "components/Tables/DashboardTableRow";
import TimelineRow from "components/Tables/TimelineRow";
import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiHappy } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import {
  IoCheckmarkDoneCircleSharp,
  IoEllipsisHorizontal,
} from "react-icons/io5";
// Data
import {
  barChartDataDashboard,
  barChartOptionsDashboard,
  lineChartDataDashboard,
  lineChartOptionsDashboard,
} from "variables/charts";
import { dashboardTableData, timelineData } from "variables/general";

export default function Dashboard() {
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      {/* Welcome Card */}
      <Card
        p="0px"
        mb="40px"
        gridArea={{ md: "1 / 1 / 2 / 3", "2xl": "auto" }}
        bgImage={medusa}
        bgSize="cover"
        bgPosition="50%"
      >
        <CardBody w="100%" h="100%">
          <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%" h="100%">
            <Flex
              flexDirection="column"
              h="100%"
              p="22px"
              minW="60%"
              lineHeight="1.6"
            >
              <Text fontSize="sm" color="gray.400" fontWeight="bold">
                Welcome back,
              </Text>
              <Text fontSize="28px" color="#fff" fontWeight="bold" mb="18px">
                Winner Takes All
              </Text>
              <Text
                fontSize="md"
                color="gray.400"
                fontWeight="normal"
                mb="auto"
              >
                Glad to see you again! <br />
                Time to play.
              </Text>
              <Spacer />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
      <SimpleGrid columns={{ sm: 2, md: 4, xl: 6 }} mb="40px" spacing="24px">
        {/* MiniStatistics Card */}
        <Card p="0px" minH="83px">
          <CardBody>
            <Flex
              flexDirection="column"
              align="center"
              justify="center"
              w="100%"
            >
              <Box w="100%" h="150px">
                <Image
                  boxSize="100%"
                  objectFit="cover"
                  src={roulette}
                  alt="Dan Abramov"
                />
              </Box>

              <Center pb="10px" pt="10px">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb="2px"
                  >
                    Roulette
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color="#fff">
                      300
                    </StatNumber>
                    <StatHelpText
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      m="0px"
                      color="green.400"
                      fontWeight="bold"
                      ps="3px"
                      fontSize="md"
                    >
                      +12%
                    </StatHelpText>
                  </Flex>
                </Stat>
              </Center>
            </Flex>
          </CardBody>
        </Card>

        {/* MiniStatistics Card */}
        <Card p="0px" minH="83px">
          <CardBody>
            <Flex
              flexDirection="column"
              align="center"
              justify="center"
              w="100%"
            >
              <Box w="100%" h="150px">
                <Image
                  boxSize="100%"
                  objectFit="cover"
                  src={slots}
                  alt="Dan Abramov"
                />
              </Box>

              <Center pb="10px" pt="10px">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb="2px"
                  >
                    Slots
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color="#fff">
                      2,300
                    </StatNumber>
                    <StatHelpText
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      m="0px"
                      color="green.400"
                      fontWeight="bold"
                      ps="3px"
                      fontSize="md"
                    >
                      +5%
                    </StatHelpText>
                  </Flex>
                </Stat>
              </Center>
            </Flex>
          </CardBody>
        </Card>
        {/* MiniStatistics Card */}
        <Card p="0px" minH="83px">
          <CardBody>
            <Flex
              flexDirection="column"
              align="center"
              justify="center"
              w="100%"
            >
              <Box w="100%" h="150px">
                <Image
                  boxSize="100%"
                  objectFit="cover"
                  src={baccarat}
                  alt="Dan Abramov"
                />
              </Box>

              <Center pb="10px" pt="10px">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb="2px"
                  >
                    Baccarat
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color="#fff">
                      700
                    </StatNumber>
                    <StatHelpText
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      m="0px"
                      color="green.400"
                      fontWeight="bold"
                      ps="3px"
                      fontSize="md"
                    >
                      +2%
                    </StatHelpText>
                  </Flex>
                </Stat>
              </Center>
            </Flex>
          </CardBody>
        </Card>
        {/* MiniStatistics Card */}
        <Card p="0px" minH="83px">
          <CardBody>
            <Flex
              flexDirection="column"
              align="center"
              justify="center"
              w="100%"
            >
              <Box w="100%" h="150px">
                <Image
                  boxSize="100%"
                  objectFit="cover"
                  src={poker}
                  alt="Dan Abramov"
                />
              </Box>

              <Center pb="10px" pt="10px">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb="2px"
                  >
                    Poker
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color="#fff">
                      1000
                    </StatNumber>
                    <StatHelpText
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      m="0px"
                      color="green.400"
                      fontWeight="bold"
                      ps="3px"
                      fontSize="md"
                    >
                      +5%
                    </StatHelpText>
                  </Flex>
                </Stat>
              </Center>
            </Flex>
          </CardBody>
        </Card>

        {/* MiniStatistics Card */}
        <Card p="0px" minH="83px">
          <CardBody>
            <Flex
              flexDirection="column"
              align="center"
              justify="center"
              w="100%"
            >
              <Box w="100%" h="150px">
                <Image
                  boxSize="100%"
                  objectFit="cover"
                  src={plinko}
                  alt="Dan Abramov"
                />
              </Box>

              <Center pb="10px" pt="10px">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb="2px"
                  >
                    Plinko
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color="#fff">
                      400
                    </StatNumber>
                    <StatHelpText
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      m="0px"
                      color="green.400"
                      fontWeight="bold"
                      ps="3px"
                      fontSize="md"
                    >
                      +3%
                    </StatHelpText>
                  </Flex>
                </Stat>
              </Center>
            </Flex>
          </CardBody>
        </Card>

        {/* MiniStatistics Card */}
        <Card p="0px" minH="83px">
          <CardBody>
            <Flex
              flexDirection="column"
              align="center"
              justify="center"
              w="100%"
            >
              <Box w="100%" h="150px">
                <Image
                  boxSize="100%"
                  objectFit="cover"
                  src={dice}
                  alt="Dan Abramov"
                />
              </Box>

              <Center pb="10px" pt="10px">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb="2px"
                  >
                    Dice
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color="#fff">
                      1,200
                    </StatNumber>
                    <StatHelpText
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      m="0px"
                      color="green.400"
                      fontWeight="bold"
                      ps="3px"
                      fontSize="md"
                    >
                      +5%
                    </StatHelpText>
                  </Flex>
                </Stat>
              </Center>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>

      {/* Projects */}
      <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="12px 0px 28px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color="#fff" fontWeight="bold" pb="8px">
              Games
            </Text>
            <Flex align="center">
              <Icon
                as={IoCheckmarkDoneCircleSharp}
                color="teal.300"
                w={4}
                h={4}
                pe="3px"
              />
              <Text fontSize="sm" color="gray.400" fontWeight="normal">
                <Text fontWeight="bold" as="span">
                  65 done
                </Text>{" "}
                this month.
              </Text>
            </Flex>
          </Flex>
        </CardHeader>
        <Table variant="simple" color="#fff">
          <Thead>
            <Tr my=".8rem" ps="0px">
              <Th
                ps="0px"
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Game
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Members
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Wager
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Completion
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {dashboardTableData.map((row, index, arr) => {
              return (
                <DashboardTableRow
                  name={row.name}
                  logo={row.logo}
                  members={row.members}
                  budget={row.budget}
                  progression={row.progression}
                  lastItem={index === arr.length - 1 ? true : false}
                />
              );
            })}
          </Tbody>
        </Table>
      </Card>
      {/* Orders Overview */}
    </Flex>
  );
}
